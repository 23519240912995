@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom Styles */
body {
  @apply bg-gray-100 text-gray-900;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Standard Nav Link */
.nav-link {
  @apply text-white hover:text-yellow-400 transition-all px-4 py-2 rounded-md;
}

/* Standard Nav Button */
.nav-button {
  @apply bg-yellow-400 text-gray-900 px-4 py-2 rounded-md hover:bg-yellow-500 transition-all;
}

